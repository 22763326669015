import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import DataTable from "src/components/DataTable";

const ChannelMappingsIndex = () => {
  const [refreshKey, setRefreshKey] = useState(0);

  const triggerRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1); // This will change the key and trigger refresh
  };

  const deleteChannelMapping = (id, name) => {
    apiRequest(`channelmapping/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error(
            "You do not have permission to delete this Channel Mapping",
          );
        } else if (response.status === 400) {
          console.log(response);
          toast.error(
            `The Channel Mapping you have selected (${id} - ${name}) is assigned to a Scoring Run and cannot be deleted`,
          );
        } else if (response.ok) {
          toast.success("Channel Mapping successfully deleted!");
          // update list after delete
          triggerRefresh();
        } else {
          console.log(response);
          toast.error("An error occurred while deleting this channel mapping.");
        }
        return response.json();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    {
      Header: "Details",
      render: (row) => (
        <Button variant="outline-primary" href={`/channelmapping/${row.id}`}>
          Channel Mapping Details
        </Button>
      ),
    },
    {
      Header: "",
      render: (row) => (
        <Button
          variant="danger"
          id={`delete_${row.id}`}
          onClick={() => deleteChannelMapping(row.id, row.name)}
        >
          <FontAwesomeIcon icon="trash" />
        </Button>
      ),
    },
  ];

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Channel Mappings</h1>
        <Button variant="outline-secondary" href="/newchannelmapping">
          Create New Channel Mapping <FontAwesomeIcon icon="plus" />
        </Button>
      </div>
      <DataTable
        endpoint="channelmapping"
        columns={columns}
        itemsPerPage={10}
        refreshKey={refreshKey}
      />
    </Stack>
  );
};

export default ChannelMappingsIndex;
