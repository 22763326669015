import { useEffect, useState } from "react";
import { Col, Dropdown, Stack, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";

const CompaniesShow = () => {
  const { id } = useParams();
  const [company, setCompany] = useState();
  const [isLoadingCompany, setIsLoadingCompany] = useState(true);

  const getCompany = () => {
    apiRequest(`company/${id}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCompany(data);
        setIsLoadingCompany(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (isLoadingCompany) {
    return <PageLoading />;
  }

  if (!isLoadingCompany && !company) {
    return <div>Company not found</div>;
  }

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between me-5">
        <h1>Company ID: {company.id}</h1>

        <Dropdown>
          <Dropdown.Toggle variant="outline-primary">Actions</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/editCompany/${id}`}>Edit</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="flex flex-wrap justify-content-between align-items-start">
        <Col xs={12} className="data-card">
          <Table size="sm" borderless>
            <tbody>
              <tr>
                <th scope="row">Name:</th>
                <td>{company.name}</td>
              </tr>
              <tr>
                <th scope="row">Active:</th>
                <td>{company.is_active ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th scope="row">Created At:</th>
                <td>{company.date_created}</td>
              </tr>
              <tr>
                <th scope="row">Description:</th>
                <td>{company.description}</td>
              </tr>
              <tr>
                <th scope="row">Retain Data:</th>
                <td>{company.data_retention_days === 0 ? "No" : "Yes"}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </div>
    </Stack>
  );
};

export default CompaniesShow;
