import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";

import { apiRequest, apiRequestPaginated } from "src/apiRequest";
import DataTable from "src/components/DataTable";
import { useUser } from "src/UserContext";

const SitesIndex = () => {
  // Until the Sites get endpoint gets the expand Keys treatment, A lot of this had has to stay, so I can get the company names
  // I'll clean up a little bit, but knowing it should change, I leaning towards leaving it messy.
  const [isLoadingSites, setLoadingSites] = useState(true);
  const [sites, setSites] = useState([]);
  const [companyNames, setCompanyNames] = useState({});
  const { cerebraUser } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  const getSites = () => {
    apiRequestPaginated(
      "site",
      {
        method: "GET",
      },
      currentPage,
      itemsPerPage,
    )
      .then((response) => response.json())
      .then((d) => {
        const data = d.data;
        setLoadingSites(false);
        if (data.length === 0) {
          setCurrentPage(Math.max(1, currentPage.current - 1));
        } else setSites(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getCompanyName = (companyId) => {
    return apiRequest(`company/${companyId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => data.name)
      .catch((error) => {
        console.error(error);
        return ""; // Return an empty string if there's an error
      });
  };

  useEffect(() => {
    getSites();
  }, [currentPage]);

  useEffect(() => {
    if (!isLoadingSites) {
      // Fetch company names for all sites
      Promise.all(sites.map((s) => getCompanyName(s.company_id)))
        .then((companyNames) => {
          const companyNameMap = {};
          sites.forEach((site, index) => {
            companyNameMap[site.id] = companyNames[index];
          });
          setCompanyNames(companyNameMap);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isLoadingSites, sites]);

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    {
      Header: "Company Name",
      accessor: "company_id",
      render: (row) => <span>{companyNames[row.id] || ""}</span>,
    },
    { Header: "Description", accessor: "description" },
    { Header: "Date Created", accessor: "date_created" },
    {
      Header: "Active",
      accessor: "is_active",
      render: (row) => <span>{row.is_active ? "Yes" : "No"}</span>,
    },
    {
      Header: "",
      render: (row) => (
        <Button variant="outline-primary" href={`/site/${row.id}`}>
          Site Details
        </Button>
      ),
    },
  ];

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Sites</h1>
        {cerebraUser && cerebraUser.role === "SuperUser" && (
          <Button variant="outline-secondary" href="/newsite">
            Create New Site <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </div>
      <DataTable endpoint="site" columns={columns} itemsPerPage={10} />
    </Stack>
  );
};

export default SitesIndex;
