import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack } from "react-bootstrap";

import DataTable from "src/components/DataTable";
import { useUser } from "src/UserContext";

function UsersIndex() {
  const { cerebraUser } = useUser();

  const columns = [
    { Header: "User ID", accessor: "id" },
    { Header: "Email", accessor: "email" },
    { Header: "Role", accessor: "role" },
    { Header: "Site ID", accessor: "site_id" },
    {
      Header: "Active",
      accessor: "is_active",
      render: (row) => (row.is_active ? "Yes" : "No"),
    },
    { Header: "Date Created", accessor: "date_created" },
    {
      Header: "Actions",
      render: (row) => (
        <Button variant="outline-primary" href={`/user/${row.id}`}>
          User Details
        </Button>
      ),
    },
  ];

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Users</h1>
        {cerebraUser && cerebraUser.role === "SuperUser" && (
          <Button variant="outline-secondary" href="/newuser">
            Create New User <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </div>
      <DataTable endpoint="user" columns={columns} itemsPerPage={10} />
    </Stack>
  );
}

export default UsersIndex;
