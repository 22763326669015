import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import "src/assets/DragDropFile.scss";

const DragDropFile = ({ onUploadSuccess, responseBody, name }) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    console.log("handle drag");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setIsLoading(true);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      createEdfFileUpload(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    setIsLoading(true);
    if (e.target.files && e.target.files[0]) {
      createEdfFileUpload(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const createEdfFileUpload = async (files) => {
    try {
      const response = await apiRequest("file/", {
        method: "POST",
        body: JSON.stringify({
          name: files[0].name,
          ...responseBody,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        submitEdfFile(data, files);
      } else {
        throw new Error("Database access failed");
      }
    } catch (error) {
      console.error(error);
      toast.error("Database access failed");
    }
  };

  const submitEdfFile = async (e, files) => {
    const formData = new FormData();
    formData.append("file", files[0]);

    try {
      const response = await fetch(e.upload_url, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        toast.success("File successfully uploaded");
        setIsLoading(false);
        onUploadSuccess(e.scoringruns[0].id);
      } else {
        console.error(response.error);
        toast.error("File failed to upload to GCS");
      }
    } catch (error) {
      console.error(error);
      toast.error("File failed to upload to GCS");
    }
  };

  return (
    <form
      id={`form_file_upload_${name}`}
      className="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      {isLoading && (
        <div className="spinner-border input-file-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <input
        ref={inputRef}
        type="file"
        id={`input_file_upload_${name}`}
        className="input-file-upload"
        multiple={false}
        onChange={handleChange}
        disabled={isLoading}
      />
      <label
        id={`label_file_upload_${name}`}
        htmlFor={`input_file_upload_${name}`}
        className={`label-file-upload ${dragActive ? "drag-active" : " "} ${
          isLoading ? "file-is-loading" : " "
        }`}
      >
        <div>
          <p>Drag and drop your file here or</p>
          <Button variant="upload" onClick={onButtonClick} disabled={isLoading}>
            Upload a file
          </Button>
        </div>
      </label>
      {dragActive && (
        <div
          id={`drag_file_element_${name}`}
          className="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </form>
  );
};

export default DragDropFile;
