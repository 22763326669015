import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  Placeholder,
  Stack,
  Table,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import "src/assets/ChannelMapping.scss";
import {
  channelNames,
  positionNames,
} from "src/components/ChannelMappings/names";
import PositionTable from "src/components/ChannelMappings/PositionTable";
import PageLoading from "src/components/PageLoading";
import { getConfig } from "src/config";

const config = getConfig();

const SuggestChannelMapping = ({ id }) => {
  const navigate = useNavigate();
  const [rawEdf, setRawEdf] = useState(null);
  const [fileMetadata, setFileMetadata] = useState(null);
  const [suggestedChannelMapping, setSuggestedChannelMapping] = useState(null);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [duplicateIndices, setDuplicateIndices] = useState([]);

  const getRawEdf = (retryCount = 0) => {
    apiRequest(`file?scoringrun_id=${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((d) => {
        const data = d.data;
        const rawEdfFile = data.find((file) => file.type === "Raw EDF");
        setRawEdf(rawEdfFile);
        if (rawEdfFile && !rawEdfFile.file_metadata) {
          throw new Error("EDF file metadata not found.");
        } else {
          setFileMetadata(rawEdfFile.file_metadata);
        }
      })
      .catch((error) => {
        if (retryCount < config.getFileRetryAttempts) {
          setTimeout(
            () => getRawEdf(retryCount + 1),
            config.getFileRetryTimeout_ms,
          );
        } else {
          setIsLoadingTable(false);
          toast.error(error.message);
        }
      });
  };

  const getSuggestedChannelMapping = () => {
    apiRequest(`file/${rawEdf.id}/suggestchannelmapping`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setSuggestedChannelMapping(data);
        setIsLoadingTable(false);
      })
      .catch((error) => toast.error(error.message));
  };

  const checkForDuplicates = (newSelectedOptions) => {
    const valueCounts = {};
    const duplicates = [];
    Object.values(newSelectedOptions).forEach((value) => {
      if (value) {
        valueCounts[value] = (valueCounts[value] || 0) + 1;
      }
    });
    Object.entries(newSelectedOptions).forEach(([index, value]) => {
      if (valueCounts[value] > 1) {
        duplicates.push(index);
      }
    });
    setDuplicateIndices(duplicates);
  };

  const handleSelectChange = (target) => {
    const name = target.name;
    const value = target.value;
    const newSelectedOptions = {
      ...selectedOptions,
      [name]: value,
    };
    setSelectedOptions(newSelectedOptions);
    checkForDuplicates(newSelectedOptions);
  };

  const updateScoringRun = (event) => {
    event.preventDefault();
    if (duplicateIndices.length > 0) {
      toast.error("Please resolve duplicate mappings before submitting.");
      return;
    }
    const formElements = event.target.elements;
    const updatedChannelMappings = {
      position: { channel_name: null, channel_index: null },
    };
    Object.values(fileMetadata?.edf_signal_headers ?? {}).forEach((c) => {
      const selectElement = formElements[`mapping-${c.index}`];
      const selectedValue = selectElement.value;
      if (selectedValue) {
        updatedChannelMappings[selectedValue] = {
          channel_name: c.label,
          channel_index: c.index,
        };
      }
    });
    positionNames.forEach((pn) => {
      const positionValue = formElements[`${pn}Position`].value;
      if (positionValue) {
        updatedChannelMappings.position[`${pn}_value`] = Number(positionValue);
      }
    });
    apiRequest(`scoringrun/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        channelmapping: updatedChannelMappings,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to edit this file.");
        } else if (response.ok) {
          // Handle success
          toast.success("File Updated Successfully!");
          navigate("/");
        } else {
          // Handle other errors
          toast.error("An error occurred while updating this file.");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while updating this file");
        console.error(error);
      });
  };

  const getSuggetedChannelName = (index) => {
    for (const [key, value] of Object.entries(suggestedChannelMapping)) {
      if (value && value.channel_index === index) return key;
    }
    return "";
  };

  useEffect(() => {
    if (id) {
      getRawEdf();
    }
  }, [id]);

  useEffect(() => {
    if (fileMetadata) getSuggestedChannelMapping();
  }, [fileMetadata]);

  useEffect(() => {
    if (!suggestedChannelMapping) return;
    const initialSelectedOptions = {};
    Object.values(fileMetadata?.edf_signal_headers ?? {}).forEach((c) => {
      const defaultValue = getSuggetedChannelName(c.index);
      if (defaultValue) {
        initialSelectedOptions[`mapping-${c.index}`] = defaultValue;
      }
    });
    setSelectedOptions(initialSelectedOptions);
    checkForDuplicates(initialSelectedOptions);
  }, [suggestedChannelMapping]);

  if (!rawEdf) {
    return <PageLoading />;
  }

  return (
    <Form onSubmit={updateScoringRun}>
      <Stack gap={4} className="body">
        <h1>Channel Mapping</h1>
        {!isLoadingTable &&
          (!fileMetadata || !fileMetadata.edf_signal_headers) && (
            <Alert variant="danger">
              <p>
                Warning - we have encountered an error processing your EDF file.
                Please verify you have uploaded a valid EDF file, and try again.
              </p>
              <Link to="/">Return to Home Page</Link>
            </Alert>
          )}
        <Stack gap={3}>
          <div className="data-card">
            <Table size="sm" responsive className="text-nowrap">
              <thead>
                <tr>
                  <th className="w-22ch">Channel Type</th>
                  <th className="w-16ch">Channel Index</th>
                  <th className="w-16ch">Channel Name</th>
                  <th className="w-16ch">Dimension</th>
                  <th>Transducer</th>
                  <th>Prefilter</th>
                </tr>
              </thead>
              <tbody className="no-th-border">
                {isLoadingTable ? (
                  <tr>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <Placeholder as="td" animation="glow" key={index}>
                        <Placeholder xs={12} />
                      </Placeholder>
                    ))}
                  </tr>
                ) : (
                  Object.values(fileMetadata?.edf_signal_headers ?? {}).map(
                    (c) => {
                      const name = `mapping-${c.index}`;
                      const isRowSelected = selectedOptions[name];
                      const isDuplicate = duplicateIndices.includes(name);
                      return (
                        <tr
                          key={c.index}
                          className={
                            isDuplicate
                              ? "table-danger"
                              : isRowSelected
                                ? "table-success"
                                : ""
                          }
                        >
                          <th scope="row" className="w-22ch">
                            <Form.Select
                              name={name}
                              defaultValue={getSuggetedChannelName(c.index)}
                              onChange={(e) => handleSelectChange(e.target)}
                            >
                              <option value="">Select mapping</option>
                              {channelNames.map((cn) => (
                                <option key={cn}>{cn}</option>
                              ))}
                            </Form.Select>
                          </th>
                          <td className="w-16ch">{c.index}</td>
                          <td className="w-16ch">{c.label}</td>
                          <td className="w-16ch">{c.units}</td>
                          <td>{c.transducer}</td>
                          <td>{c.prefiltering}</td>
                        </tr>
                      );
                    },
                  )
                )}
              </tbody>
            </Table>
          </div>
          <div className="data-card">
            <PositionTable form />
          </div>
          <Button
            variant="secondary"
            type="submit"
            disabled={
              !fileMetadata ||
              !fileMetadata.edf_signal_headers ||
              isLoadingTable
            }
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};

export default SuggestChannelMapping;
