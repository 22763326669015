import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack } from "react-bootstrap";

import DataTable from "src/components/DataTable";

function StudiesIndex() {
  const columns = [
    { Header: "Study ID", accessor: "id" },
    { Header: "Created At", accessor: "date_created" },
    {
      Header: "Created By",
      accessor: "user_id",
      render: (row) => <span>{row.user.email}</span>,
    },
    { Header: "Description", accessor: "site_id" },
    {
      Header: "",
      render: (row) => (
        <Button variant="outline-primary" href={`/study/${row.id}`}>
          Study Details
        </Button>
      ),
    },
  ];

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Studies</h1>
        <Button variant="outline-secondary" href="/newstudy">
          Create New Study <FontAwesomeIcon icon="plus" />
        </Button>
      </div>
      <DataTable
        endpoint="study"
        columns={columns}
        itemsPerPage={10}
        urlSearchParams={{ expand_keys: "user" }}
      />
    </Stack>
  );
}

export default StudiesIndex;
