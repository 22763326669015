import { Stack } from "react-bootstrap";

function SoftwareLabel() {
  return (
    <Stack gap={4} className="body">
      <h1>Cerebra Sleep System (CSS) MY ORP</h1>
      <div>
        <b>Device Name</b>
        <p>Cerebra Sleep System</p>
        <b>Identifiers of device</b>
        <p>Part number for CSS: 700015</p>
        <p>Software version for CSS MY ORP: 1.3</p>
        <p>UDI code for CSS: +B6007000141/$$+7700014/16D20240909Y</p>
        <p>Release Date: October 31, 2024</p>
        <b>Manufacturer</b>
        <p>Cerebra Medical Ltd.</p>
        <p>1470 Wilson Place, Unit B</p>
        <p>Winnipeg, Manitoba, R3T 3N9</p>
        <p>CANADA</p>
        <b>
          Indications for Use, Warnings and Cautions, and other instructions for
          use
        </b>
        <p>Please refer to the provided technician/user manuals</p>
        <b>Rx Only</b>
        <p>
          US federal law restricts this device to sale (or use) on the order of
          a licensed practitioner
        </p>
      </div>
    </Stack>
  );
}

export default SoftwareLabel;
