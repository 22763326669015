import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";

const UserEdit = () => {
  const [sites, setSites] = useState([]);
  const [editUser, setEditUser] = useState();
  const [isActive, setisActive] = useState();
  const [isLoadingSites, setIsLoadingSites] = useState(true);
  const [isLoadingEditUser, setIsLoadingEditUser] = useState(true);
  const { id } = useParams();

  const navigate = useNavigate();

  const updateUser = (event) => {
    event.preventDefault();

    const userSite = event.target.userSite.value;
    const userRole = event.target.userRole.value;
    const userIsActive = isActive;

    apiRequest(`user/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        site_id: userSite,
        role_name: userRole,
        is_active: userIsActive,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to edit this user.");
        } else if (response.ok) {
          // Handle success
          toast.success("User Updated Successfully!");
          navigate("/users");
        } else {
          // Handle other errors
          toast.error("An error occurred while updating this user.");
        }
      })
      .catch((error) => {
        toast.error("An error occured while updating this user");
        console.error(error);
      });
  };

  const getSites = () => {
    apiRequest("site", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((d) => {
        const data = d.data;
        setSites(data);
        setIsLoadingSites(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getUser = (id) => {
    apiRequest(`user/${id}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setEditUser(data);
        setisActive(data.is_active);
        setIsLoadingEditUser(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getSites();
    getUser(id);
  }, []);

  if (isLoadingEditUser || isLoadingSites) {
    return <PageLoading />;
  }

  return (
    <Stack gap={4} className="body">
      <h1>Edit User</h1>
      <Form onSubmit={updateUser}>
        <Row>
          <Col as="h3" xs="auto">
            User Details
          </Col>
          <Col xs={4}>
            <Stack gap={3}>
              <Form.Group controlId="userRole">
                <Form.Label>Role</Form.Label>
                <Form.Select name="userRole" defaultValue={editUser.role}>
                  <option value="CompanyAdmin">Company Admin</option>
                  <option value="SiteAdmin">Site Admin</option>
                  <option value="SuperUser">Super User</option>
                  <option value="User">User</option>
                  <option value="ORPUser">ORPUser</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="userSite">
                <Form.Label>Site</Form.Label>
                <Form.Select name="userSite" defaultValue={editUser.site_id}>
                  {!isLoadingSites &&
                    sites.map((s) => (
                      <option value={s.id} key={s.id}>
                        {s.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="userActive">
                <p>Active?</p>
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  value
                  checked={isActive}
                  name="isActive"
                  onChange={() => setisActive(true)}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value={false}
                  checked={!isActive}
                  name="isNotActive"
                  onChange={() => setisActive(false)}
                />
              </Form.Group>
            </Stack>
          </Col>
        </Row>
        <hr />
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Form>
    </Stack>
  );
};

export default UserEdit;
