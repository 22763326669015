import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";

const CompanyEdit = () => {
  const [currentCompany, setCurrentCompany] = useState();
  const { id } = useParams();

  const navigate = useNavigate();

  const updateCompany = (event) => {
    event.preventDefault();

    apiRequest(`company/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        name: event.target.companyName.value,
        description: event.target.companyDescription.value,
        is_active: event.target.companyIsActive.value,
        data_retention_days: event.target.companyDataRetentionDays.value,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to edit this company.");
        } else if (response.ok) {
          toast.success("Company Updated Successfully!");
          navigate(`/company/${id}`);
        } else {
          toast.error("An error occurred while updating this company.");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while updating this company.");
        console.error(error);
      });
  };

  const getCompany = (id) => {
    apiRequest(`company/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setCurrentCompany(data))
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    getCompany(id);
  }, [id]);

  if (!currentCompany) {
    return <PageLoading />;
  }

  return (
    <Stack gap={4} className="body">
      <h1>Edit Company</h1>
      <Form onSubmit={updateCompany}>
        <Row>
          <Col as="h3" xs="auto">
            Company Details
          </Col>
          <Col xs={4}>
            <Stack gap={3}>
              <Form.Group controlId="companyName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  defaultValue={currentCompany.name}
                />
              </Form.Group>
              <Form.Group controlId="companyDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  defaultValue={currentCompany.description}
                />
              </Form.Group>
              <div>
                <p>Retain Data for this Company?</p>
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  value={-1}
                  defaultChecked={currentCompany.data_retention_days < 0}
                  name="companyDataRetentionDays"
                  id="doesRetainData"
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value={0}
                  defaultChecked={currentCompany.data_retention_days >= 0}
                  name="companyDataRetentionDays"
                  id="doesNotRetainData"
                />
              </div>
              <div>
                <p>Active?</p>
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  value
                  defaultChecked={currentCompany.is_active === true}
                  name="companyIsActive"
                  id="isActive"
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value={false}
                  defaultChecked={currentCompany.is_active === false}
                  name="companyIsActive"
                  id="isNotActive"
                />
              </div>
            </Stack>
          </Col>
        </Row>
        <hr />
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Form>
    </Stack>
  );
};

export default CompanyEdit;
