import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack } from "react-bootstrap";

import DataTable from "src/components/DataTable";
import { useUser } from "src/UserContext";

const CompaniesIndex = () => {
  const { cerebraUser } = useUser();

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Description", accessor: "description" },
    { Header: "Date Created", accessor: "date_created" },
    {
      Header: "Active",
      accessor: "is_active",
      render: (row) => <span>{row.is_active ? "Yes" : "No"}</span>,
    },
    {
      Header: "",
      render: (row) => (
        <Button variant="outline-primary" href={`/company/${row.id}`}>
          Company Details
        </Button>
      ),
    },
  ];

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Companies</h1>
        {cerebraUser && cerebraUser.role === "SuperUser" && (
          <Button variant="outline-secondary" href="/newcompany">
            Create New Company <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </div>
      <DataTable endpoint="company" columns={columns} itemsPerPage={10} />
    </Stack>
  );
};

export default CompaniesIndex;
