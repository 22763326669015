import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

import { apiRequestPaginated } from "src/apiRequest";

const DataTable = ({
  endpoint,
  columns,
  itemsPerPage = 10,
  urlSearchParams = {},
  refreshKey,
}) => {
  // const [filters, setFilters] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });

  const fetchData = (page, sortConfig) => {
    apiRequestPaginated(
      endpoint,
      {
        method: "GET",
      },
      page,
      itemsPerPage,
      {
        sort_by: sortConfig.key,
        sort_order: sortConfig.direction,
        ...urlSearchParams,
      },
    )
      .then((response) => response.json())
      .then((d) => {
        setData(d.data);
        setTotalPages(Math.ceil(d.total_count / itemsPerPage));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  const handleSortClick = (e, accessor) => {
    e.preventDefault();
    handleSort(accessor);
  };

  // const handleFilterChange = (accessor, value) => {
  //   setFilters({
  //     ...filters,
  //     [accessor]: value,
  //   });
  // };

  const renderSortIcon = (accessor) => {
    const sortUpClass =
      sortConfig.key === accessor && sortConfig.direction === "asc"
        ? "sort-control-icon sort-control-active"
        : "sort-control-icon sort-control-inactive";
    const sortDownClass =
      sortConfig.key === accessor && sortConfig.direction === "desc"
        ? "sort-control-icon sort-control-active"
        : "sort-control-icon sort-control-inactive";
    return (
      <div className="sort-control">
        <span className={sortUpClass}>
          <FontAwesomeIcon icon="sort-up" />
        </span>
        <span className={sortDownClass}>
          <FontAwesomeIcon icon="sort-down" />
        </span>
      </div>
    );
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxButtons = 7; // How many buttons to show around the current page
    const ellipsisThreshold = 2; // When to show ellipses

    if (totalPages <= maxButtons) {
      // Show all pages if total pages is small
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      if (currentPage > ellipsisThreshold + 1) {
        pageNumbers.push("ellipsis-start");
      }

      const start = Math.max(currentPage - 1, 2);
      const end = Math.min(currentPage + 1, totalPages - 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - ellipsisThreshold) {
        pageNumbers.push("ellipsis-end");
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  useEffect(() => {
    fetchData(currentPage, sortConfig);
  }, [currentPage, sortConfig, refreshKey]);

  return (
    <div className="data-card">
      <Table size="sm" responsive>
        <thead>
          {/* <tr style={{ borderBottom: "white" }}> */}
          <tr>
            {columns.map((col) => (
              <th
                className="datatable-header"
                key={col.accessor || col.Header}
                onClick={(e) =>
                  col.accessor && handleSortClick(e, col.accessor)
                }
              >
                <div className="d-flex align-items-center justify-content-between">
                  <span>
                    {col.Header} {col.accessor && renderSortIcon(col.accessor)}
                  </span>
                </div>
              </th>
            ))}
          </tr>
          {/* <tr>
            {columns.map((col) => (
              <th key={`filter-${col.accessor || col.Header}`}>
                {col.accessor && (
                  <Form.Control
                    type="text"
                    placeholder={`Search ${col.Header}`}
                    value={filters[col.accessor] || ""}
                    onChange={(e) =>
                      handleFilterChange(col.accessor, e.target.value)
                    }
                    className="datatable-filter-input"
                  />
                )}
              </th>
            ))}
          </tr> */}
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td key={col.accessor || col.Header}>
                  {col.render ? col.render(row) : row[col.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Pagination>
          <Pagination.First
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />

          {generatePageNumbers().map((page, index) => {
            if (page === "ellipsis-start" || page === "ellipsis-end") {
              return <Pagination.Ellipsis key={index} />;
            } else {
              return (
                <Pagination.Item
                  key={index}
                  active={page === currentPage}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Pagination.Item>
              );
            }
          })}

          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DataTable;
