import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Placeholder,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import "src/assets/ChannelMapping.scss";
import PageLoading from "src/components/PageLoading";
import { getConfig } from "src/config";

const config = getConfig();

const AttachChannelMapping = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rawEdf, setRawEdf] = useState({});
  const [channelMappings, setChannelMappings] = useState([]);
  const [selectedChannelMapping, setSelectedChannelMapping] = useState({});
  const [isLoadingRawEdf, setIsLoadingRawEdf] = useState(true);
  const [isLoadingFileMetadata, setIsLoadingFileMetadata] = useState(true);
  const [isLoadingChannelMappings, setIsLoadingChannelMappings] =
    useState(true);

  const getRawEdf = (retryCount = 0) => {
    apiRequest(`file?scoringrun_id=${id}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((d) => {
        const data = d.data;
        const rawEdfFile = data.find((file) => file.type === "Raw EDF");
        setRawEdf(rawEdfFile);
        setIsLoadingRawEdf(false);
        if (rawEdfFile && !rawEdfFile.file_metadata) {
          throw new Error("EDF file metadata not found. Please refresh page.");
        } else {
          setIsLoadingFileMetadata(false);
        }
      })
      .catch((error) => {
        if (retryCount < config.getFileRetryAttempts) {
          setTimeout(
            () => getRawEdf(retryCount + 1),
            config.getFileRetryTimeout_ms,
          );
        } else {
          setIsLoadingFileMetadata(false);
          toast.error(error.message);
        }
      });
  };

  const getChannelMappings = () => {
    apiRequest("channelmapping", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((d) => {
        const data = d.data;
        setChannelMappings(data);
        setIsLoadingChannelMappings(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateFile = (event) => {
    event.preventDefault();
    const channelMapping = event.target.channelMappings.value;
    apiRequest(`file/${rawEdf.id}`, {
      method: "PATCH",
      body: JSON.stringify({
        channelmapping_id: channelMapping,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to edit this file.");
        } else if (response.ok) {
          // Handle success
          toast.success("File Updated Successfully!");
          navigate(-1);
        } else {
          // Handle other errors
          toast.error("An error occurred while updating this file.");
        }
      })
      .catch((error) => {
        toast.error("An error occured while updating this file");
        console.error(error);
      });
  };

  useEffect(() => {
    getRawEdf();
    getChannelMappings();
  }, []);

  function selectChannelMapping(event) {
    setSelectedChannelMapping(
      channelMappings.find(
        (mapping) => mapping.id === parseInt(event.target.value),
      ),
    );
  }

  function mapChannel(signalHeader) {
    const { index, label } = signalHeader;
    // Check if the index matches any channel_index in the mapping
    for (const key in selectedChannelMapping) {
      const channel = selectedChannelMapping[key];
      if (channel.channel_index === index) {
        return { channelType: key, match: "index" };
      }
    }
    // If no match found based on index, check if the label matches any channel_name in the mapping
    for (const key in selectedChannelMapping) {
      const channel = selectedChannelMapping[key];
      if (channel.channel_name === label) {
        return { channelType: key, match: "label" };
      }
    }
    // If no match found based on index or label, return null
    return { channelType: null, match: false };
  }

  if (isLoadingRawEdf) {
    return <PageLoading />;
  }

  return (
    <Form onSubmit={updateFile}>
      <Stack gap={4} className="body">
        <h1>Attach Channel Mapping to File</h1>
        <Row xs={2}>
          <Col>
            <div className="data-card">
              <Table size="sm" borderless className="w-auto">
                <tbody>
                  <tr>
                    <th scope="row">File Id:</th>
                    <td>{rawEdf.id}</td>
                  </tr>
                  <tr>
                    <th scope="row">Name:</th>
                    <td>{rawEdf.name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Description:</th>
                    <td>{rawEdf.description}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col>
            <div className="data-card">
              <Table size="sm" borderless className="w-auto">
                <tbody>
                  <tr>
                    <th scope="row">Study Id:</th>
                    <td>{rawEdf.study.id}</td>
                  </tr>
                  <tr>
                    <th scope="row">ScoringRun Id:</th>
                    <td>{id}</td>
                  </tr>
                  <tr>
                    <th scope="row">User Id:</th>
                    <td>{rawEdf.user_id}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col xs={12} />
        </Row>
        <h2>Channel List</h2>
        <Form.Group controlId="channelMappings">
          <Form.Select
            name="channelMappings"
            onChange={selectChannelMapping}
            className="w-auto"
          >
            <option value="">Select Channel Mapping</option>
            {!isLoadingChannelMappings &&
              channelMappings.map((ch) => (
                <option key={ch.id} value={ch.id}>
                  {ch.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <div className="data-card">
          <Table size="sm" responsive className="text-nowrap">
            <tbody>
              <tr>
                <th className="w-16ch">Channel Type</th>
                <th className="w-16ch">Channel Index</th>
                <th className="w-16ch">Channel Name</th>
                <th className="w-16ch">Dimension</th>
                <th>Transducer</th>
                <th>Prefilter</th>
              </tr>
              {isLoadingFileMetadata ? (
                <tr>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <Placeholder as="td" animation="glow" key={index}>
                      <Placeholder xs={12} />
                    </Placeholder>
                  ))}
                </tr>
              ) : (
                Object.values(
                  rawEdf.file_metadata?.edf_signal_headers ?? {},
                ).map((c) => {
                  const { channelType, match } = mapChannel(c);
                  const rowClass = match ? "table-success" : "";
                  return (
                    <tr key={c.index} className={rowClass}>
                      <th className="w-16ch" scope="row">
                        {channelType}
                      </th>
                      <td className="w-16ch">
                        {c.index}
                        {match === "index" && (
                          <FontAwesomeIcon
                            className="green-icon"
                            icon="check-circle"
                          />
                        )}
                      </td>
                      <td className="w-16ch">
                        {c.label}
                        {match === "label" && (
                          <FontAwesomeIcon
                            className="green-icon"
                            icon="check-circle"
                          />
                        )}
                      </td>
                      <td className="w-16ch">{c.units}</td>
                      <td>{c.transducer}</td>
                      <td>{c.prefiltering}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Stack>
    </Form>
  );
};

export default AttachChannelMapping;
